.dialog-content {
	padding-top: 10px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: row;
	
	.profile-back-button {
		flex: 0 0 30px;
		margin-top: 3px;
		cursor: pointer;
	}

	.dialog-label {
		flex: 10;
		display: inline-block;
	}

	.edit-button {
		flex: 0 0 30px;
		float: right;
		cursor: pointer;
	}
}

.edit-field {
	margin-bottom: 10px;
}
