@import "./styles/app-variables.scss";

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "NeueHaasGroteskDisp";
  src: url("./fonts/NHaasGroteskDSPro-55Rg.eot");
  src: url("./fonts/NHaasGroteskDSPro-55Rg.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/NHaasGroteskDSPro-55Rg.woff2") format("woff2"),
    url("./fonts/NHaasGroteskDSPro-55Rg.woff") format("woff"),
    url("./fonts/NHaasGroteskDSPro-55Rg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "NeueHaasGroteskDisp", "Segoe UI", "Roboto", sans-serif !important;
}

html,
body,
#root {
  font-family: "NeueHaasGroteskDisp", "Segoe UI", "Roboto", sans-serif !important;
  height: 100%;
  overflow: auto;
}

body {
  margin: 0;
  font-family: "NeueHaasGroteskDisp", "Segoe UI", "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: "NeueHaasGroteskDisp", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// TYPOGRAPHY //
body,
html {
  font-size: 16px; // set base app font size
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "NeueHaasGroteskDisp";
  line-height: 1;
}

h1 {
  color: $astra-primary-color;
  font-size: $font-size-xxl;
}

h2 {
  color: $astra-primary-color;
  font-size: $font-size-xl;
}

h3 {
  color: $astra-primary-color;
  font-size: $font-size-lg;
  font-weight: 600;
}

h4 {
  color: #000000;
  font-size: $font-size-lg;
  font-weight: 500;
}

h5 {
  color: #000000;
  font-size: $font-size-md;
  text-align: left;
} // used for = ['therapist name']

.credentials {
  font-size: $font-size-sm;
  text-transform: uppercase;
  text-align: left;
} // used for = ['therapist credentials']

h6 {
  color: #000000;
  font-size: $font-size-sm;
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
  margin: 1rem 0 0.75rem 0;
} // used for = ['dark box all-caps sm headers']

p {
  font-size: $font-size-base;
  font-weight: 500;
  color: #000;

  &.lead {
    font-size: $font-size-base;
    margin: 1rem 0 2rem 0;
  }

  &.big {
    font-size: $font-size-md;
  }

  &.little {
    font-size: $font-size-sm;
  }

  &.text-light {
    opacity: 0.75;
  }

  a {
    color: $astra-primary-color;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

// LAYOUT //
.container-fluid {
  height: calc(100vh - 4.5rem);
  overflow-y: auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  width: 90vw;
  max-width: 50rem;
  margin: auto;
  text-align: center;
}

.content {
  margin: 0 auto;
  &.sm {
    max-width: 31rem; // +1 to account for 15rem wide form rows
  }

  &.md {
    max-width: 40rem;
  }

  &.lg {
    max-width: 50rem;
  }
}

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

// ELEMENTS //
// ...Buttons...
.btn-row {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  margin-top: 1rem;
  .btn {
    margin: 0.5rem;
  }
}

.btn {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: $font-size-base;
  min-width: $default-btn-width;
  border-radius: $button-radius;
  padding: 0.7rem 1rem;
  line-height: 1;
  cursor: pointer;
  @include soft-shadow;
  &:disabled {
    cursor: initial;
  }
  &.btn-text {
    background-color: transparent;
    padding: 0.25rem;
    min-width: auto;
    box-shadow: none;
    &:not(.icon-left),
    &:not(.icon-right) {
      text-decoration: underline;
    }
    &:hover {
      text-decoration: none;
      .text {
        text-decoration: none;
      }
    }
  }
  &.btn-primary {
    background-color: $astra-primary-color;
    color: #ffffff;
    &:disabled {
      background-color: $astra-primary-faded;
      color: #ffffff;
    }
  }
  &.btn-primary-outline {
    background-color: #ffffff;
    border: 1px solid $astra-primary-color;
    color: $astra-primary-color;
    box-shadow: none;
  }
  &.btn-secondary {
    background-color: #ffffff;
    color: $astra-primary-color;
    &:disabled {
      background-color: #c0c0c0;
      color: #ffffff;
    }
  }
  &.icon-left {
    .icon {
      margin-right: 0.5rem;
    }
    .text {
      text-decoration: underline;
    }
  }
  &.icon-right {
    .icon {
      margin-left: 0.5rem;
    }
    .text {
      text-decoration: underline;
    }
  }
}

// ... Page Blocks...
.dark-block {
  border-radius: $large-radius;
  background-color: $astra-tertiary-color;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  @include soft-shadow;
  &.split {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    > * {
      width: 50%;
      flex: 1 0 17.5rem;
    }
  }
}

.light-block {
  padding: 1rem;
  background-color: white;
  width: 100%;
  border-radius: $large-radius;
  @include soft-shadow;
}

// ...Avatar...
.avatar {
  // profile pic
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  margin-right: 1rem;
}

// FORMS //
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0rem -0.25rem;
  .textbox-container {
    margin: 0.5rem 0.25rem;
    flex: 1 0 15rem;
  }
  .select-container {
    margin: 0.5rem 0.25rem;
    flex: 1 0 9rem;
  }
}

.form-col {
  > * {
    margin-bottom: 0.75rem;
  }
}

.field-error {
  font-size: $font-size-base;
  color: #c70000;
  text-align: left;
  font-style: italic;
}

textarea {
  width: 100%;
  padding: 1rem;
  border-radius: $large-radius;
  color: #000000;
  &:focus {
    @include input-focus;
  }
}

// GLOBAL //
.astra-icon {
  margin: 1.5rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

// OTHER //
// ...Screen Reader Only...
.sr-only {
  display: absolute;
  left: -100rem;
  top: -100rem;
  opacity: 0;
  height: 0;
  width: 0;
  line-height: 0;
}

// TODO //
// ...Find a better spot for these...
$mobile-nav-height: 5rem;

.mobile-nav {
  background-color: $astra-tertiary-color;
  height: $mobile-nav-height;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 888;
  width: 100%;

  .row-spaced {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 25rem;
    margin: 0 auto;
  }

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: $mobile-nav-height;
    color: $astra-primary-color;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    &:not(.active) {
      opacity: 0.75;
      svg {
        path {
          fill: $astra-primary-color;
        }
      }
      &:hover {
        opacity: 1;
      }
    }
  }

  svg {
    margin-bottom: 0.25rem;
    path {
      fill: $astra-primary-color;
    }
  }
}
