@import "../../global.scss";

$outer-height: 30vh;
$inner-height: $outer-height - 10vh;

$outer-width: 24vw;
$inner-width: $outer-width - 10vw;
$inner-button-width: $inner-width - 4vw;

#login {
}

.login-form {
  padding-top: 10vh;
  width: $outer-width;
  min-width: 500px;
  margin: auto;
  text-align: center;
  color: $astra-primary-color;

  .astra-icon {
    margin: auto;
    align-items: center;
  }

  .login-form-inner {
    .row-field {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .field-label {
        flex: 1;
        text-align: right;
        padding-right: 10px;
      }

      .field-control {
        flex: 1;
        text-align: left;
        padding-left: 10px;

        input {
          width: 100%;
        }
      }
    }

    .login-error {
      color: red;
      margin-top: 30px;
    }
  }
}

.login-registration-prompt {
  margin: 20px 0;
  cursor: pointer;
}

.login-registration-button {
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: 38px; /* 211.111% */
  border-radius: $button-radius;
  height: 50px;
  padding: 0 60px;
}
