@import "../../global.scss";

$outer-height: 30vh;
$inner-height: $outer-height - 10vh;

$outer-width: 24vw;
$button-width: 20vw;
$inner-width: $outer-width - 10vw;
$inner-button-width: $inner-width - 4vw;

.profile-form {
  padding-top: 10vh;
  width: $outer-width;
  min-width: 300px;
  margin: auto;
  text-align: center;
  color: $astra-primary-color;

  .astra-icon {
    margin: auto;
    align-items: center;
  }

  .my-account,
  .account-management {
    margin-top: 5vh;
    border-radius: $default-radius;
    line-height: 44px;
    background-color: $astra-tertiary-color;
    align-items: center;
    padding-bottom: 30px;

    .section-header {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .personal-info,
  .contact-info,
  .billing-management {
    padding-top: 3vh;
    align-items: center;

    > button {
      width: $outer-width;
      background-color: $astra-secondary-color;
      margin: auto;
      border-radius: $button-radius;

      .button-caption {
        .icon {
          display: inline-block;
          padding-right: 10px;
          vertical-align: middle;
        }

        .label {
          display: inline-block;
          padding-top: 3px;
        }
      }

      &:disabled {
        background-color: silver;
        color: #eee;
      }
    }
  }
}
