@import "../../global.scss";


.label-text-field-container {
	width: 100%;
	background-color: white;
	height: 36px;
	border-radius: 5px;
	padding: 6px 10px 0 10px;

	display: flex;
	flex-direction: row;

	.field-label {
		flex: 1 0;
		text-align: left;
		white-space: nowrap;
		overflow-x: hidden;
	}

	.field-value {
		flex: 1;
		text-align: right;
	}

	.field-input {
		text-align: right;
	}
}